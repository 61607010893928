<template>
  <div>
    <h1>
      <manual-article-add-dialog
          v-if="canManage"
          :projectId="this.projectId">
      </manual-article-add-dialog>
    </h1>
    <manual-article-search class="pt-5"></manual-article-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
              v-if="showPages && pageCount > 1"
              class="my-3"
              v-model="pagination.page"
              :length="pageCount"
              :total-visible="7"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
                v-model="pagination.rowPerPage"
                item-text="label"
                item-value="attribute"
                :items="countPerPage"
                label="Show items"
                style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
                item-text="label"
                item-value="attribute"
                :items="sortAttributes"
                label="sortBy"
                v-model="sort.sortBy"
                style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                  v-if="sort.desc"
                  title="sortAsc"
                  @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="articles"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.url="{ item }">
        <a target="_blank" :href="item.url" class="black--text">
          {{ item.url }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {FETCH_PROJECT_MANUAL_ARTICLES, SET_FILTERS } from "@/store/modules/projectsManualArticles/actions";

import BaseListPage from "./base/BaseListPage";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/projectsManualArticles/state";
import ManualArticleSearch from "@/views/manualArticle/list/ManualArticleSearch";
import ManualArticleAddDialog from "@/views/manualArticle/item/ManualArticleAddDialog";
import {RESOURCE_PROJECTS_MANAGEMENT} from "@/store/modules/admin/getters";
import acl from "@/mixin/acl";
import {NEW_CREATED_ARTICLE} from "../utils/events";
import {EventBus} from "../utils";

export default {
  name: "ProjectManualArticlesPage",
  extends: BaseListPage,
  mixins: [acl],
  components: {
    ManualArticleSearch,
    ManualArticleAddDialog
  },
  props: {
    projectId: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      isLoading: false,
      countPerPage: [
        {
          attribute: 5,
          label: "5",
        },
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "URL",
          value: "url",
          width: "500",
        },
        {
          text: "Language",
          value: "lang",
          width: "10",
        },
        {
          text: "Status",
          value: "status",
          width: "50",
        },
        {
          text: "Published",
          value: "publishedAt",
          width: "200",
        },
        {
          text: "Created",
          value: "createdAt",
          width: "200",
        },
        {
          text: "Title",
          value: "title",
          width: "500",
        },
        {
          text: "Text",
          value: "text",
          width: "500",
        },
      ],
      sortAttributes: [
        {
          attribute: "createdAt",
          label: "Created",
        },
        {
          attribute: "publishedAt",
          label: "Published",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("projectsManualArticles", [
      "items",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("projectsManualArticles", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PROJECTS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    articles: function () {
      if (this.isLoading) {
        return [];
      }
      return this.items;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`projectsManualArticles/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`projectsManualArticles/${FETCH_PROJECT_MANUAL_ARTICLES}`, this.projectId).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.applyRouteParams();
    this.fetchItems();
  },
  created() {
    EventBus.$on(NEW_CREATED_ARTICLE, () => {
      this.fetchItems();
    });
  },
};
</script>
