<template>
  <div>
    <log-search></log-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
              v-if="showPages && pageCount > 1"
              class="my-3"
              v-model="pagination.page"
              :length="pageCount"
              :total-visible="7"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
                v-model="pagination.rowPerPage"
                item-text="label"
                item-value="attribute"
                :items="countPerPage"
                label="Show items"
                style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
                item-text="label"
                item-value="attribute"
                :items="sortAttributes"
                label="sortBy"
                v-model="sort.sortBy"
                style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                  v-if="sort.desc"
                  title="sortAsc"
                  @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="items"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.accountId="{ item }">
        <span>{{ item.accountId }} {{ item.accountName }}</span>
      </template>
      <template v-slot:item.userId="{ item }">
        <span>{{ item.userId }} {{ item.userName }}</span>
      </template>
      <template v-slot:item.rootSession="{ item }">
        <span>{{ item.rootSession ? "Yes" : "No" }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { FETCH_PROJECT_LOGS, SET_FILTERS } from "@/store/modules/projectsLog/actions";

import BaseListPage from "./base/BaseListPage";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/projectsLog/state";
import LogSearch from "@/views/project/log-list/LogSearch";

export default {
  name: "ProjectLogsPage",
  extends: BaseListPage,
  components: {LogSearch},
  props: {
    projectId: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      isLoading: false,
      countPerPage: [
        {
          attribute: 5,
          label: "5",
        },
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "User",
          value: "userId",
        },
        {
          text: "Action",
          value: "primaryAction",
        },
        {
          text: "Details",
          value: "secondaryAction",
        },
        {
          text: "Message",
          value: "message",
          width: "400",
        },
        {
          text: "Date",
          value: "createdAt",
        },
      ],
      sortAttributes: [
        {
          attribute: "createdAt",
          label: "Created",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("projectsLog", [
      "logs",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("projectsLog", ["sort", "pagination", "filters"]),

    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    items: function () {
      if (this.isLoading) {
        return [];
      }
      return this.logs;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`projectsLog/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`projectsLog/${FETCH_PROJECT_LOGS}`, this.projectId).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.applyRouteParams();
    this.fetchItems();
  },
};
</script>
