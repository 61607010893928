<template>
  <v-container fluid>
    <v-row style="max-height: 75px">
      <v-icon v-if="editedItem.status === 'started' " color="green" class="mx-2 mb-2">
        mdi-arrow-right-drop-circle-outline
      </v-icon>
      <v-icon v-else-if="editedItem.status === 'paused' " color="orange" class="mx-2 mb-2">
        mdi-pause-circle-outline
      </v-icon>
      <v-icon v-else-if="editedItem.status === 'deleted'" color="grey" class="mx-2 mb-2">
        mdi-close-box-outline
      </v-icon>
      <v-icon v-else-if="editedItem.status === 'new'" color="blue" class="mx-2 mb-2">
        mdi-new-box
      </v-icon>
      <v-icon v-else color="grey" class="mx-2 mb-2">
      </v-icon>
      <v-col cols="2" class="mb-1" v-if="projectItem.id == this.id"
             :loading="isLoading"
      >
        {{ projectItem.name }}
      </v-col>
    </v-row>
    <v-row>
      <v-btn
          class="my-0 py-0"
          color="primary"
          depressed
          text
          small
          tile
          plain
          @click="backToProjects"
      >
        <v-icon>mdi-arrow-left-thin</v-icon>
        Back to projects
      </v-btn>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-col cols="12" lg="4" md="4" class="mb-3">
        <v-card outlined tile :loading="isLoading">
          <v-card-text class="pa-4">
            <v-row no-gutters>
              <v-col cols="4">Id</v-col>
              <v-col v-if="projectItem.id == this.id" class="ml-3">{{ projectItem.id }}</v-col>
            </v-row>
            <v-row>
              <v-col v-if="projectItem.id == this.id" cols="4">Status</v-col>
              <v-col v-if="projectItem.id == this.id && projectItem.status === 'started'" style="color:green;">
                {{ projectItem.status }}
              </v-col>
              <v-col v-else-if="projectItem.id == this.id && projectItem.status === 'paused'" style="color:orange;">
                {{ projectItem.status }}
              </v-col>
              <v-col v-else-if="projectItem.id == this.id && projectItem.status === 'deleted'" style="color:grey;">
                {{ projectItem.status }}
              </v-col>
              <v-col v-else-if="projectItem.id == this.id && projectItem.status === 'new'" style="color:dodgerblue;">
                {{ projectItem.status }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" class="my-5"> Lock Status</v-col>
              <v-col class="mt-4 ml-2" v-if="projectItem.lockStatus && projectItem.id == this.id">
                <v-tooltip bottom :max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon v-if="projectItem.lockStatus === 'locked'" class="mr-2" style="color: #F44336">
                      mdi-lock-outline
                      </v-icon>
                      <v-icon v-else class="mr-2" style="color: #67BB6A">
                        mdi-lock-open-outline
                      </v-icon>
                    </span>
                  </template>
                  <span v-if="projectItem.lockStatus === 'locked'">Project is locked by timeline worker</span>
                  <span v-else>Project is unlocked</span>
                </v-tooltip>
              </v-col>
              <v-btn v-if="projectItem.lockStatus === 'locked' && !isLoading" class="mt-3 mr-16" color="#fe753b"
                     outlined small @click="unlockProject()">unlock
              </v-btn>
              <v-spacer class="mr-16"></v-spacer>
              <v-spacer class="mr-16"></v-spacer>
              <v-spacer class="mr-10"></v-spacer>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">Account Name</v-col>
              <v-col v-if="projectItem.id == this.id" class="ml-3">{{ projectItem.accountName }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Account Email</v-col>
              <v-col v-if="projectItem.id == this.id">{{ projectItem.accountEmail }}</v-col>
            </v-row>

            <v-row>
              <v-col cols="4">Project Type</v-col>
              <v-col v-if="projectItem.id == this.id && projectItem.type === 'feed'" style="color: #009ca9">{{ projectItem.type }}</v-col>
              <v-col v-if="projectItem.id == this.id && projectItem.type === 'keyword'" style="color: #bb6bd9">{{ projectItem.type }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Project Platforms</v-col>
              <v-col v-if="projectItem.id == this.id">
                <img
                    v-if="projectItem.sourceTypeList.includes('media')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/newspaper.svg')"
                    alt=""
                />
                <img
                    v-if="projectItem.sourceTypeList.includes('facebook')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/project_type_fb.svg')"
                    alt=""
                />
                <img
                    v-if="projectItem.sourceTypeList.includes('twitter')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/project_type_tw.svg')"
                    alt=""
                />
                <img
                    v-if="projectItem.sourceTypeList.includes('youtube')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/project_type_yt.svg')"
                    alt=""
                />
                <img
                    v-if="projectItem.sourceTypeList.includes('telegram')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/telegram.svg')"
                    alt=""
                />
                <img
                    v-if="projectItem.sourceTypeList.includes('linkedin')"
                    class="mt-1 mr-1"
                    :src="require('@/assets/linkedin.svg')"
                    alt=""
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card
            outlined
            tile
            :loading="isLoading"
            :ripple="false"
            style="cursor: default"
        >
          <v-row>
            <v-col cols="7" class="ma-0">
              <v-list flat disabled>
                <v-list-item-group>
                  <v-list-item dense>
                    <v-card-text class="pa-0" disabled>Created</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.createdAt }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Updated</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.updatedAt }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Paused</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.pausedAt || '-' }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="pa-0">Deleted</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.deletedAt || '-' }}
                    </v-card-text>
                  </v-list-item>
                </v-list-item-group>
                <v-divider class="my-6"></v-divider>
                <v-list-item-group class="mb-4">
                  <v-list-item dense>
                    <v-card-text class="pa-0">Last Run</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.lastCheckedAt }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item dense>
                    <v-card-text class="mb-1 pa-0">Next Run</v-card-text>
                    <v-card-text class="pa-0"
                    >{{ projectItem.nextRun }}
                    </v-card-text>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="5" sm="5" md="5" lg="5">
              <v-list flat disabled>
                <v-list-item-group>
                  <v-list-item>
                    <v-card-text class="pa-0"
                    >Articles Count:
                    </v-card-text>
                    <v-card-text v-if="projectItem.id == this.id" class="pa-0" style="color:dodgerblue;"
                    >{{ projectItem.articlesCount }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item>
                    <v-card-text class="pa-0"
                    >Custom Sources:
                    </v-card-text>
                    <v-card-text v-if="projectItem.id == this.id" class="pa-0"
                    >{{ projectItem.sourcesCount }}/
                      <router-link
                        :to="{
                          name: 'CustomSourceListPage',
                          query: { projectId: this.id },
                        }"
                      ><span class="green--text"  style="pointer-events: all">
                        {{ projectItem.activeSourcesCount }}
                      </span></router-link>

                    </v-card-text>
                  </v-list-item>
                  <v-list-item>
                    <v-card-text class="pa-0"
                    >Daily Reports:
                    </v-card-text>
                    <v-card-text v-if="projectItem.id == this.id" class="pa-0"
                    >{{ projectItem.enableNotifications }}
                    </v-card-text>
                  </v-list-item>
                  <v-list-item>
                    <v-card-text class="pa-0"
                    >Digest Reports:
                    </v-card-text>
                    <v-card-text v-if="projectItem.id == this.id" class="pa-0"
                    >{{ projectItem.enableReports }}
                    </v-card-text>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import {
  FETCH_PROJECT,
  UPDATE_PROJECT,
} from "@/store/modules/project/actions";

import acl from "@/mixin/acl";
import {RESOURCE_PROJECTS_MANAGEMENT} from "@/store/modules/admin/getters";

export default {
  name: "ProjectItem",
  mixins: [acl],
  props: {
    id: {
      required: true,
    },
    prevRoute: {
      required: true,
    },
  },
  inheritAttrs: true,
  data() {
    return {
      isLoading: true,
      editedItem: {
        status: null,
        lockStatus: null,
      },
      defaultItem: {
        status: null,
        lockStatus: null,
      },
      errorMsg: "",
    };
  },
  computed: {
    ...mapGetters("project", ["projectItem"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PROJECTS_MANAGEMENT]);
    },
  },
  watch: {
    "editedItem.enable": {
      handler: function () {
        // this.editItem();
      },
      deep: true,
    },
  },
  methods: {
    backToProjects() {
      this.$router.push({name: "ProjectsListPage"});
    },
    unlockProject() {
      const vm = this;
      vm.$store.dispatch(`project/${UPDATE_PROJECT}`, {"id": this.id, "editedItem": {"status": "unlocked"}}).then(
          () => {
            vm.fetchItem();
          }
      )
    },
    fetchItem: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`project/${FETCH_PROJECT}`, this.id).then(
          () => {
            vm.isLoading = false;
            this.showPages = true;
            let {
              status,
              lockStatus,
            } = vm.projectItem;
            let objItem = {
              status,
              lockStatus,
            };
            this.editedItem = _.merge({}, objItem);
            this.defaultItem = _.merge({}, objItem);
          },
          (error) => {
            console.log(error);
          }
      );
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  beforeMount() {
    this.fetchItem();
  },
};
</script>
