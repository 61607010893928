<template>
  <div>
    <project-item :prevRoute="prevRoute" :id="id"></project-item>
    <v-tabs v-if="canSeeProjects" v-model="tab">
      <v-tab :to="{ name: 'ProjectItemLogs'}">Project logs</v-tab>
      <v-tab :to="{ name: 'ProjectManualArticlesPage'}">Manual articles</v-tab>
      <v-tab :to="{ name: 'ProjectTasksPage'}">Project Update</v-tab>
    </v-tabs>
    <component
      v-if="canSeeProjects"
      :is="nestedPage"
      :projectId="id"
    ></component>
  </div>
</template>

<script>

import acl from "@/mixin/acl";
import {RESOURCE_PROJECTS} from "@/store/modules/admin/getters";
import ProjectItem from "@/views/project/item/ProjectItem";
import {SET_PROJECT_ID} from "@/store/modules/project/actions";
import ProjectLogsPage from "@/views/ProjectLogsPage";
import ProjectManualArticlesPage from "@/views/ProjectManualArticlesPage";
import ProjectTasksPage from "@/views/ProjectTasksPage";

export default {
  name: "ProjectPage",
  mixins: [acl],
  props: {
    id: {
      required: true,
    },
  },
  components: {
    ProjectItem,
    ProjectLogsPage,
    ProjectManualArticlesPage,
    ProjectTasksPage,
  },
  data() {
    return {
      prevRoute: null,
      tab: null,
      items: { projectItem: ProjectItem },
    };
  },
  computed: {
    nestedPage: function () {
      return this.$route.meta.nestedComponent;
    },
    canSeeProjects: function () {
      return this.hasAccess([RESOURCE_PROJECTS]);
    },

  },
  beforeMount() {
    this.$store.dispatch(`project/${SET_PROJECT_ID}`, this.id);
  },
};
</script>
