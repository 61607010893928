<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          depressed
          rounded
          color="primary"
          style="float: right"
          class="mt-6 mx-1"
          v-bind="attrs"
          v-on="on"
      >Add manual article
      </v-btn
      >
    </template>
    <v-card>
      <v-form @submit.prevent="saveArticle" ref="articleForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer
          >
          <v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2"> Add Manual Article</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="newItem.url"
                    required
                    :rules="[inputRequired, urlRule]"
                    label="URL"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="newItem.title"
                    :rules="[inputRequired]"
                    label="Title"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="newItem.text"
                    outlined
                    name="text"
                    label="Text"
                    hint="Add article text"
                    :rules="[inputRequired]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                    v-model="newItem.lang"
                    item-value="attribute"
                    item-text="label"
                    :items="supportedLanguages"
                    :rules="[inputRequired]"
                    label="Language"
                    required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vc-date-picker v-model="newItem.publishedAt" mode="dateTime" is24hr class="mt-16"
                  :rules="[inputRequired]"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                        :rules="[inputRequired]"
                        label="Publication Date"
                        :value="inputValue"
                        v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="newItem.imageUrl"
                    :rules="[urlRule]"
                    label="Image Url"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
            <div v-for="(error, key) in errors" :key="key">
              {{ prepareErrorMessage(error, key) }}
            </div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel</v-btn>
          <v-btn color="primary" text @click="saveArticle"> Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {EventBus} from "@/utils";
import {NEW_CREATED_ARTICLE} from "@/utils/events";
import {CREATE_ARTICLE} from "@/store/modules/projectsManualArticles/actions";

export default {
  name: "ManualArticleAddDialog",
  props: {
    projectId: {
      required: true,
    },
  },
  data() {
    return {
      items: [],
      errors: {},
      dialog: false,
      date: null,
      menuModal: true,
      newItem: {
        url: null,
        lang: null,
        imageUrl: null,
        title: null,
        text: null,
        publishedAt: null,
      },
      defaultItem: {
        url: null,
        lang: null,
        imageUrl: null,
        title: null,
        text: null,
        publishedAt: null,
      },
      supportedLanguages: [
        {
          attribute: 'en',
          label: "EN",
        },
        {
          attribute: 'uk',
          label: "UK",
        },
        {
          attribute: 'ru',
          label: "RU",
        },
      ],
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      minCharsRule: (v) => (v && v.length >= 3) || "Input too short!",
      maxCharsRule: (v) => (v && v.length <= 40) || "Input too long!",
      langRule: (v) => ["en", "ru", "uk"].includes(v) || "Supported languages `uk`, `ru`, `en`",
      urlRule: (v) => {
        const regex = new RegExp(
            "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*",
            "i"
        )
        return !v || regex.test(v) || 'Incorrect URL';
      }
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.errors = {};
      this.$refs.articleForm.resetValidation();
      this.newItem = _.merge({}, this.defaultItem);
    },
    saveArticle() {
      let vm = this;
      return this.createArticle(function (response) {
        vm.close();
        let {id} = response.data;
        vm.newItem = Object.assign({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_ARTICLE, id);
      });
    },
    createArticle() {
      let vm = this;
      if (!vm.$refs.articleForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let payload = _.merge({ projectId: this.projectId }, this.newItem);
        this.$store
            .dispatch(`projectsManualArticles/${CREATE_ARTICLE}`, payload)
            .then(
                (response) => {
                  this.dataLoading = false;
                  if (response.result) {
                    // this.accountForm = defaultValues;
                    // this.$emit("submitted", response.data);
                    vm.errors = {};
                    vm.close();
                    let {id} = response.data;
                    vm.newItem = Object.assign({}, vm.defaultItem);
                    EventBus.$emit(NEW_CREATED_ARTICLE, id);
                  } else {
                    vm.errors = response.details;
                  }
                },
                (err) => {
                  console.log("Err", err);
                }
            )
            .catch(() => {});
      }
    },
    getCurrentDate(){
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, '0');
      let day = String(currentDate.getDate()).padStart(2, '0');
      let hours = String(currentDate.getHours()).padStart(2, '0');
      let minutes = String(currentDate.getMinutes()).padStart(2, '0');

      return `${year}/${month}/${day} ${hours}:${minutes}`;
    }
  },
};
</script>
